.trainig-container {
  min-width: 140px;
  height: 88vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 15px;
  /* padding: 4px; */
  border-radius: 25px;
  position: relative;
  box-shadow: 2px 8px 8px #00000069;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  object-fit: cover;
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.trainig-content-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  /* height: 100%; */
  /* min-height: 80px; */
  background-color: #414c5b85;
  padding: 20px 10px 10px 10px;
  z-index: 2;

}

.progress-container {
  display: flex;
  /*margin-top: 35px;*/
}

.progress-container .progress-btn {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #fff;
  outline: none;
  border: none;
  margin: 0 auto;
}

.progress-container .btn-title {
  display: block;
  color: #fff;
  margin-bottom: 10px;
  /* text-align: center; */
}

.progress-container .progress-btn-wrapper {
  margin: 0 12px;
}

.trainig-container .dots {
  display: flex;
  margin-left: 10px;
}

.trainig-container .dots .dot {
  width: 5px;
  height: 10px;
  border-radius: 25px;
  background-color: #fff;
  margin: 0 10px;
}

.progress-container .back-btn {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  margin-right: 35px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
}

.text-area-wrapper {
  display: flex;
  align-items: end;
  position: relative;
  width: 50%;
}



.text-area {
  width: 50%;
  height: 100px;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 25px;
  color: #fff;
  outline: none;
  resize: none;
  display: block;
}

.text-area__disabled {
  background: #1322354D;
  border-radius: 0px 0px 10px 10px;
  border: none;
  font-family: Arial;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}


.form-btn {
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  letter-spacing: 10px;
  text-align: center;
  margin-left: 25px;

}

.collaps-btn {
  position: absolute;
  top: -40px;
  right: 100px;
  width: 80px;
  height: 40px;
  /* border: 1px solid #fff; */
  background-color: #414c5b85;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.message-container {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 450px;
  height: 100%;
  background: #ffffffde;
  z-index: 9;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 15px;
  /* overflow-y: auto; */
  transition: all ease-in-out 0.2s;
}



.message-container ul li {
  color: #37445F;
  padding: 10px 0;
}

.message-container .message-close {
  border: none;
  background-color: transparent;
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 10px;
}

.custom-input {
  background-color: transparent;
  /* width: 50%; */
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 25px;
  outline: none;
  color: #fff;
  font-weight: 700;
}

.training__congratulations .custom-btn {
  margin: 0 !important;
}

.training__image-wrapper {
  min-height: 400px;
  min-width: 500px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
  text-align: center;
}

.image_input2-label {
  /* width: 67px;
  height: 14px; */
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.2em;
  text-align: center;
  border: 1px solid #1D2432;
  padding: 13px 35px;
  border-radius: 20px;
  cursor: pointer;
}

.image_input2-label:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.image-upload__manifest-wrapper {
  background: #1322354D;
  border-radius: 0px 10px 10px 10px;
  border: none;
  font-family: Arial;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  max-width: 545px;
  min-height: 173px;
  display: flex;
  line-height: 24px;
  /* align-items: center; */
  padding: 15px;
}

.image-upload__manifest-wrapper.final {
  max-width: 636px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.image-upload__spark-word {
  /* max-width: 320px; */
  /* height: 40px; */
  background: #13223573;
  border-radius: 10px 10px 0px 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
  /* line-height: 37px; */
  letter-spacing: 0em;
  /* text-align: center; */
  padding-left: 10px;
  color: #FFFFFF;
  /* width: 100%; */
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
}

.image-upload__manifest-wrapper-text {
  width: 299px;
  /* padding: 20px; */
  word-break: break-all;
  color: #FFFFFF;

}

.image-upload__manifest-wrapper-text.final {
  width: 406px
}

.image-upload__submit-btn {
  padding: 12px 55px !important;
  border-radius: 35px !important;
  min-width: 150px !important;
  color: #FFFFFF !important;
  background: #1D2432 !important;
  font-family: Montserrat !important;
}

.image-upload__manifest-wrapper-background {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 39px;
  margin: 9px;
  min-width: 120px;
  min-height: 100px;
  position: relative;
  background-position: center center;
}

.image-upload__manifest-wrapper-background.upload:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d243263;
}

.image-upload__manifest-wrapper-background.final {
  content: unset;
}

.mobile-video-container {
  display: none;
  width: 100%;
  height: 250px;
}

.volume-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffffff;
}

.play-control {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  color: #fff;
  display: none;
}

.video-wrapper {
  position: relative;
  width: 100%;
  /* padding-bottom: 56.25%; */
  /* 16:9 aspect ratio */
  overflow: hidden;
}

.video-wrapper video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
}

.video-wrapper:hover .play-control {
  display: flex;
}

.manifest-statement-text-area-wrapper {
  display: flex;
  align-items: end
}

@media only screen and (max-width: 600px) {
  #trainingVideo {
    height: 100vh !important;
    position: relative;
  }
 /* .trainig-container::after{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
background: rgb(47, 55, 70);
  background: linear-gradient(0deg, rgba(47, 55, 70, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    z-index: 999;
  } */

  .progress-title-mobile{
    display: none !important;
  }

  .trainig-container {
    /* background-image: none !important; */
    /* height: 100% !important; */
    padding: 0;
    margin: 0;
    /* max-height: 85vh; */
    border-radius: 0;
    position: relative;
    /* height: 83vh; */
    /* margin-top: -80px; */

  }

  .message-container {
    position: absolute !important;
    overflow-y: auto !important;
    top: 0px;
    max-width: 350px;
  }

  .progress-wrapper {
    display: none;
    overflow-x: auto;
    max-width: 360px;
    width: 100%;
  }

  .trainig-content-overlay {
    /* position: static; */
    margin-top: 150px;
    padding: 22px;
    right: 0;
    bottom: 0;
    background-color: #444d5f;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    /* margin: 0 10px; */
    width: auto;

  }
  .collaps-btn{

    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    background-color: #444d5f;
  }
  .mbo-center{
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
  .text-area{
    width: 80% !important;
  }

  .back-btn {
    display: none !important;
  }

  .message-container {
    position: relative;
  }

  .mobile-video-container {
    display: block;
  }

    .image-upload__manifest-wrapper-text.final {
      width: 100%;
      border: none !important;
    }

  .video-container {
    /* display: none; */
    width: 450px !important;
    height: 250px !important;
    object-fit: contain !important;
    /* top: 0;
    right: 0; */
    /* min-height: 450px !important; */
  }

  .mob-icons {
    display: none !important;
  }

  .collaps-btn {
    right: 0;
  }

  .text-area-wrapper {
    display: block;
    position: static;
    width: 100%;
  }

  .text-area-wrapper p {
    display: block;
    position: static;
  }

  .spark-work-inputs{
    display: block !important;
  }

  .text-area {
    width: auto;
    display: block;
  }
  .custom-input{
    width: 80%;
    margin: 0 auto 20px auto;
    display: block;
    border-radius: 10px;
    padding: 10px;
  }
  .audio-mob-wrapper{
    display: block !important;
  }
  .image-upload__manifest-wrapper{
    display: block;
  }
    .image-upload__manifest-wrapper-background{
      width: 50px;
      height: 50px;
      padding: 0;
      margin: auto;
      border-radius: 10px;
    }
        .image-upload__manifest-wrapper.final{
          width: 90%;
        }

    .review-mob-wrapper{
      display: block !important;
    }

    .image-upload-mob-wrapper{
      display: block !important;
    }

  .manifest-statement-text-area-wrapper {
    display: block;
  }
  .training-audio-placeholder{
    /* display: none; */
    width: 80%;
    height: 50px;
  }
  .finish-mob-wrapper{
    display: block !important;
  }
    .text-area.manifest-show{
      background-color: #343f51;
      border: none;
      height: auto !important;
    }


    /* .mob-audio-download{
      display: block !important;
    } */

  .manifest-statement-text-area-wrapper-btns {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  }

  .download-mob-body{
    display: block !important;
    width: 75% !important;
  }
  /* .download-mob-body-content{
    width: 70% !important;
  } */
}
